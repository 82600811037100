import React from 'react';
import type { FormProps, IChangeEvent } from '@rjsf/core';
import { withTheme } from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/mui';
import type { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

export type TpRJSFSchema = RJSFSchema;

type FormData = Record<string, unknown>;

type DynamicFormProps = Omit<FormProps, 'onSubmit' | 'validator'> & {
  onSubmit: (state: { formData: FormData }) => void;
};

const Form = withTheme(MuiTheme);

export function DynamicForm({ onSubmit, ...props }: DynamicFormProps): React.ReactElement {
  return (
    <Form
      {...props}
      validator={validator}
      onSubmit={(data: IChangeEvent): void => {
        if (!data.formData) {
          return;
        }
        onSubmit({ formData: data.formData });
      }}
    />
  );
}
